import { FC } from "react";

const ProfileEditButton: FC = () => {
  return (
    // <button type="button" className="w-full border-t h-1/2">
    //   Edit Profile
    // </button>
    <div className="w-full border-t border-l h-5/12">
      <p> </p>
    </div>
  );
};

export default ProfileEditButton;
